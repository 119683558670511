import { Details_details$data } from './__generated__/Details_details.graphql'

export const makeCelebrities = (
  celebrities: Details_details$data['celebrities']
): { [genre: string]: string[] } => {
  return celebrities.reduce<{ [genre: string]: string[] }>(
    (res, celeb) => ({
      ...res,
      [celeb.genre]: [...(res[celeb.genre] ?? []), celeb.name]
    }),
    {}
  )
}
