import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'

// マウント後に判定したい場合は true を渡して実行する
export const useRouterHash = (
  lazy?: boolean
): [string, (newHash: string) => void] => {
  const router = useRouter()
  const [hash, _setHash] = useState<string>(
    !lazy ? extractHash(router.asPath) : ''
  )
  useEffect(() => {
    _setHash(extractHash(router.asPath))
  }, [router.asPath])
  const setHash = useCallback(
    (newHash: string) => {
      router.replace({ hash: newHash }, undefined, { shallow: true })
    },
    [router.replace]
  )
  return [hash, setHash]
}

const extractHash = (url: string): string => {
  return url.split('#')[1] ?? ''
}
