import { FC } from 'react'
import { css } from '@emotion/react'
import { CemeteryPriceTableColumn } from './CemeteryPriceTableColumn'
import { useFragment } from 'react-relay/hooks'
import { graphql } from 'relay-runtime'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import { orderedSectionType } from '@lifedot/utils'
import {
  CemeteryPriceTable_cemetery$data,
  CemeteryPriceTable_cemetery$key
} from './__generated__/CemeteryPriceTable_cemetery.graphql'

const styles = {
  table: css({
    width: '100%',
    borderCollapse: 'collapse',
    borderSpacing: 0,
    'th, td': {
      textAlign: 'center',
      padding: '4px 8px',
      border: `1px solid ${palette.gray2}`
    },
    th: {
      backgroundColor: palette.background,
      fontWeight: 'bold'
    }
  })
}

interface CemeteryPriceTableProps {
  cemetery: CemeteryPriceTable_cemetery$key
}

export const CemeteryPriceTable: FC<CemeteryPriceTableProps> = ({
  cemetery
}) => {
  const data = useFragment(fragment, cemetery)
  const {
    cemeteryId,
    best_price_by_category: prices,
    section_types: sectionTypes
  } = data
  if (sectionTypes.length < 1) return null

  const orderedSectionTypes = orderedSectionType([...sectionTypes])

  return (
    <table css={styles.table}>
      <tbody>
        <tr>
          {orderedSectionTypes.map(({ name, code }) => (
            <th key={code} css={typography.textS}>
              {name}
            </th>
          ))}
        </tr>
        <tr>
          {orderedSectionTypes.map(({ code }) => (
            <CemeteryPriceTableColumn
              cemeteryId={cemeteryId}
              sectionTypeCode={code}
              price={
                prices[
                  code as keyof CemeteryPriceTable_cemetery$data['best_price_by_category']
                ]
              }
              key={code}
            />
          ))}
        </tr>
      </tbody>
    </table>
  )
}

const fragment = graphql`
  fragment CemeteryPriceTable_cemetery on Cemetery {
    cemeteryId: id
    section_types {
      name
      code
    }
    best_price_by_category {
      normal {
        price1
        price1_unit
        price2
        price2_unit
        purchase_price
        purchase_price_unit
        calculated_purchase_price
        category_code
        purchase_price_option
        price3
        simulated_price3
        price4
        price4_name
        lot_area
      }
      nokotsu {
        price1
        price1_unit
        price2
        price2_unit
        purchase_price
        purchase_price_unit
        calculated_purchase_price
        category_code
        purchase_price_option
        price3
        simulated_price3
        price4
        price4_name
        lot_area
      }
      jumoku {
        price1
        price1_unit
        price2
        price2_unit
        purchase_price
        purchase_price_unit
        calculated_purchase_price
        category_code
        purchase_price_option
        price3
        simulated_price3
        price4
        price4_name
        lot_area
      }
      eitai_kuyo {
        price1
        price1_unit
        price2
        price2_unit
        purchase_price
        purchase_price_unit
        calculated_purchase_price
        category_code
        purchase_price_option
        price3
        simulated_price3
        price4
        price4_name
        lot_area
      }
    }
  }
`
