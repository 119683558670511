import { FC } from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import Link from 'next/link'
import { router } from '@lifedot/router'
import { PriceBase } from '@lifedot/utils'
import { makeCemeteryPrice } from '@/utils/makeCemeteryPrice'

const styles = {
  price: css({
    color: palette.alert
  }),
  note: css({
    color: palette.gray6
  })
}

interface CemeteryPriceTableColumnProps {
  cemeteryId: number
  sectionTypeCode: string
  price: PriceBase | null
}

export const CemeteryPriceTableColumn: FC<CemeteryPriceTableColumnProps> = ({
  cemeteryId,
  sectionTypeCode,
  price
}) => {
  const columnItem = makeCemeteryPrice(price)

  if (!columnItem)
    return (
      <td css={typography.textS}>
        <span css={styles.note}>価格未掲載</span>
      </td>
    )

  const { priceNumber, priceSuffix, supplement } = columnItem

  return (
    <td css={typography.textS}>
      <Link
        href={router.price(cemeteryId) + `#${sectionTypeCode}-detail`}
        prefetch={false}
        passHref
      >
        <a css={[typography.textM, styles.price]}>
          <b>{priceNumber}</b>
          <small>{priceSuffix}</small>
        </a>
      </Link>
      <br />
      <small css={styles.note}>{supplement}</small>
    </td>
  )
}
