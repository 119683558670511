import { FC } from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'

const styles = {
  tableWrap: css({
    width: '100%'
  }),
  table: css({
    width: '100%',
    wordBreak: 'break-word',
    'th, td': {
      padding: '4px 8px',
      border: `1px solid ${palette.gray2}`
    },
    th: {
      textAlign: 'center',
      backgroundColor: palette.gray1,
      width: '30%',
      minWidth: 100,
      whiteSpace: 'pre-wrap',
      lineHeight: 1.4
    }
  })
}

export interface DetailTableProps {
  details: Array<{ label: string; content: string }>
}

export const DetailTable: FC<DetailTableProps> = ({ details }) => {
  return (
    <div css={styles.tableWrap}>
      <table css={[typography.textS, styles.table]}>
        <tbody>
          {details.map(({ label, content }) => (
            <tr key={label}>
              <th>{label}</th>
              <td>{label === '空き状況' ? <b>{content}</b> : content}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
