import { FC, useMemo } from 'react'
import { css } from '@emotion/react'
import { graphql } from 'relay-runtime'
import { useFragment } from 'react-relay/hooks'
import {
  Spec_spec$data,
  Spec_spec$key
} from './__generated__/Spec_spec.graphql'
import { typography } from '@lifedot/styles/typography'
import { mq } from '@lifedot/styles/mediaQuery'
import { palette } from '@lifedot/styles/palette'
import { displayableDetails, displayableSpecs } from './dependencies'

const styles = {
  labels: css({
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 4
  }),
  label: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 4,
    width: 'calc(25% - 4px)',
    height: 40,
    lineHeight: '16px',
    position: 'relative',
    margin: '4px 4px 0 0',
    [mq('sp')]: {
      width: 'calc(50% - 4px)'
    }
  }),
  default: css({
    border: `1px solid ${palette.gray3}`,
    color: palette.gray4
  }),
  exist: css({
    border: `1px solid ${palette.main02}`,
    backgroundColor: palette.background,
    color: palette.main02
  }),
  table: css(typography.textS, {
    width: '100%',
    marginTop: 12,
    'th, td': {
      padding: '4px 12px',
      border: `1px solid ${palette.gray2}`
    },
    th: {
      backgroundColor: palette.gray1,
      width: '30%',
      minWidth: 100,
      textAlign: 'center'
    }
  }),
  text: css({
    whiteSpace: 'pre-wrap'
  })
}

interface SpecProps {
  spec: Spec_spec$key
  optionalSpecs?: Array<
    keyof Spec_spec$data['flags'] | keyof Spec_spec$data['details']
  >
}

export const Spec: FC<SpecProps> = ({ spec, optionalSpecs }) => {
  const data = useFragment(fragment, spec)
  const specs = useMemo(() => displayableSpecs(optionalSpecs), [optionalSpecs])
  const details = useMemo(
    () => displayableDetails(optionalSpecs),
    [optionalSpecs]
  )

  return (
    <>
      {specs.length > 0 && (
        <div css={[typography.textM, styles.labels]}>
          {specs.map((item) => (
            <div
              css={[
                styles.label,
                data.flags[item.flagName] ? styles.exist : styles.default
              ]}
              key={item.flagName}
            >
              <p css={styles.text}>{item.label}</p>
            </div>
          ))}
        </div>
      )}
      {details.length > 0 && (
        <table css={styles.table}>
          <tbody>
            {details
              .filter((item) => data.details[item.flagName])
              .map((item) => (
                <tr key={item.flagName}>
                  <th>{item.label}</th>
                  <td colSpan={3}>{data.details[item.flagName]}</td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </>
  )
}
const fragment = graphql`
  fragment Spec_spec on Cemetery {
    flags {
      danka_system_unnecessary
      dining
      goushi
      hall
      lifetime_request
      parking
      pet
      resident_staff
      shop
      shuttle_bus
      successor_unnecessary
      universal_facility
    }
    details {
      facility
      universal_facility
    }
  }
`
