/**
 * @generated SignedSource<<d864b5303b68020063121bd4de2778df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type Spec_spec$data = {
  readonly flags: {
    readonly danka_system_unnecessary: boolean
    readonly dining: boolean
    readonly goushi: boolean
    readonly hall: boolean
    readonly lifetime_request: boolean
    readonly parking: boolean
    readonly pet: boolean
    readonly resident_staff: boolean
    readonly shop: boolean
    readonly shuttle_bus: boolean
    readonly successor_unnecessary: boolean
    readonly universal_facility: boolean
  }
  readonly details: {
    readonly facility: string
    readonly universal_facility: string
  }
  readonly ' $fragmentType': 'Spec_spec'
}
export type Spec_spec$key = {
  readonly ' $data'?: Spec_spec$data
  readonly ' $fragmentSpreads': FragmentRefs<'Spec_spec'>
}

const node: ReaderFragment = (function () {
  var v0 = {
    alias: null,
    args: null,
    kind: 'ScalarField',
    name: 'universal_facility',
    storageKey: null
  }
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'Spec_spec',
    selections: [
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryFlags',
        kind: 'LinkedField',
        name: 'flags',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'danka_system_unnecessary',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'dining',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'goushi',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'hall',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'lifetime_request',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'parking',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'pet',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'resident_staff',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'shop',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'shuttle_bus',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'successor_unnecessary',
            storageKey: null
          },
          v0 /*: any*/
        ],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryDetails',
        kind: 'LinkedField',
        name: 'details',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'facility',
            storageKey: null
          },
          v0 /*: any*/
        ],
        storageKey: null
      }
    ],
    type: 'Cemetery',
    abstractKey: null
  }
})()

;(node as any).hash = '3bfe2510d557a088a075b3b60b809f05'

export default node
