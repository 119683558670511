/**
 * @generated SignedSource<<527e24d65ac68e2186ddd78a40117f66>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type CemeteryPriceTable_cemetery$data = {
  readonly cemeteryId: number
  readonly section_types: ReadonlyArray<{
    readonly name: string
    readonly code: string
  }>
  readonly best_price_by_category: {
    readonly normal: {
      readonly price1: number | null
      readonly price1_unit: string | null
      readonly price2: number | null
      readonly price2_unit: string | null
      readonly purchase_price: number | null
      readonly purchase_price_unit: string | null
      readonly calculated_purchase_price: number | null
      readonly category_code: string
      readonly purchase_price_option: string
      readonly price3: number | null
      readonly simulated_price3: number | null
      readonly price4: number | null
      readonly price4_name: string | null
      readonly lot_area: number | null
    } | null
    readonly nokotsu: {
      readonly price1: number | null
      readonly price1_unit: string | null
      readonly price2: number | null
      readonly price2_unit: string | null
      readonly purchase_price: number | null
      readonly purchase_price_unit: string | null
      readonly calculated_purchase_price: number | null
      readonly category_code: string
      readonly purchase_price_option: string
      readonly price3: number | null
      readonly simulated_price3: number | null
      readonly price4: number | null
      readonly price4_name: string | null
      readonly lot_area: number | null
    } | null
    readonly jumoku: {
      readonly price1: number | null
      readonly price1_unit: string | null
      readonly price2: number | null
      readonly price2_unit: string | null
      readonly purchase_price: number | null
      readonly purchase_price_unit: string | null
      readonly calculated_purchase_price: number | null
      readonly category_code: string
      readonly purchase_price_option: string
      readonly price3: number | null
      readonly simulated_price3: number | null
      readonly price4: number | null
      readonly price4_name: string | null
      readonly lot_area: number | null
    } | null
    readonly eitai_kuyo: {
      readonly price1: number | null
      readonly price1_unit: string | null
      readonly price2: number | null
      readonly price2_unit: string | null
      readonly purchase_price: number | null
      readonly purchase_price_unit: string | null
      readonly calculated_purchase_price: number | null
      readonly category_code: string
      readonly purchase_price_option: string
      readonly price3: number | null
      readonly simulated_price3: number | null
      readonly price4: number | null
      readonly price4_name: string | null
      readonly lot_area: number | null
    } | null
  }
  readonly ' $fragmentType': 'CemeteryPriceTable_cemetery'
}
export type CemeteryPriceTable_cemetery$key = {
  readonly ' $data'?: CemeteryPriceTable_cemetery$data
  readonly ' $fragmentSpreads': FragmentRefs<'CemeteryPriceTable_cemetery'>
}

const node: ReaderFragment = (function () {
  var v0 = [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'price1',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'price1_unit',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'price2',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'price2_unit',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'purchase_price',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'purchase_price_unit',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'calculated_purchase_price',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'category_code',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'purchase_price_option',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'price3',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'simulated_price3',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'price4',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'price4_name',
      storageKey: null
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'lot_area',
      storageKey: null
    }
  ]
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'CemeteryPriceTable_cemetery',
    selections: [
      {
        alias: 'cemeteryId',
        args: null,
        kind: 'ScalarField',
        name: 'id',
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryChoice',
        kind: 'LinkedField',
        name: 'section_types',
        plural: true,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'name',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'code',
            storageKey: null
          }
        ],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryBestPriceByCategory',
        kind: 'LinkedField',
        name: 'best_price_by_category',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            concreteType: 'CemeteryPriceAndSpec',
            kind: 'LinkedField',
            name: 'normal',
            plural: false,
            selections: v0 /*: any*/,
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: 'CemeteryPriceAndSpec',
            kind: 'LinkedField',
            name: 'nokotsu',
            plural: false,
            selections: v0 /*: any*/,
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: 'CemeteryPriceAndSpec',
            kind: 'LinkedField',
            name: 'jumoku',
            plural: false,
            selections: v0 /*: any*/,
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: 'CemeteryPriceAndSpec',
            kind: 'LinkedField',
            name: 'eitai_kuyo',
            plural: false,
            selections: v0 /*: any*/,
            storageKey: null
          }
        ],
        storageKey: null
      }
    ],
    type: 'Cemetery',
    abstractKey: null
  }
})()

;(node as any).hash = '9dcf0eee61bfb67d5385a4463e0ad2cb'

export default node
