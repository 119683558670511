import { css } from '@emotion/react'
import { Label } from '@lifedot/atoms/Label'
import { FC } from 'react'

const styles = {
  labels: css({
    display: 'flex',
    flexWrap: 'wrap',
    li: {
      margin: '0 4px 4px 0'
    }
  })
}

export interface LabelsProps {
  labels: string[]
}

export const Labels: FC<LabelsProps> = ({ labels }) => {
  return (
    <ul css={styles.labels}>
      {labels.map((label) => (
        <li key={label}>
          <Label large emphasis>
            {label}
          </Label>
        </li>
      ))}
    </ul>
  )
}
