import { FC, useMemo, Fragment } from 'react'
import { graphql } from 'relay-runtime'
import { useFragment } from 'react-relay/hooks'
import {
  Details_details$data,
  Details_details$key
} from './__generated__/Details_details.graphql'
import { CemeteryTypeCode } from '@lifedot/constants/searchChoices'
import { makeCelebrities } from './dependencies'
import { markup } from '@lifedot/utils'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'

const styles = {
  section: css({
    backgroundColor: palette.gray1,
    borderRadius: 4,
    padding: '16px 20px'
  }),
  table: css({
    width: '100%',
    marginTop: 8,
    'th, td': {
      padding: '8px 0'
    },
    th: {
      width: 110,
      fontWeight: 'bold',
      verticalAlign: 'top'
    },
    td: {
      whiteSpace: 'pre-wrap'
    }
  })
}

interface DetailsProps {
  details: Details_details$key
  full?: boolean
}

export const Details: FC<DetailsProps> = ({ details, full = true }) => {
  const {
    name,
    kana,
    address,
    celebrities,
    details: data,
    cemetery_types,
    section_types,
    sects
  } = useFragment(fragment, details)

  return (
    <section css={styles.section}>
      <h3 css={typography.textXL}>公式詳細情報</h3>
      <table css={[typography.textS, styles.table]}>
        <tbody>
          <tr>
            <th>施設名</th>
            <td>{`${name}${kana ? `(${kana})` : ''}`}</td>
          </tr>
          <tr>
            <th>所在地</th>
            <td>
              {data.postal_code && (
                <>
                  〒{data.postal_code}
                  <br />
                </>
              )}
              {address}
            </td>
          </tr>
          <tr>
            <th>霊園種別</th>
            <td>
              {cemetery_types
                .filter(({ name }) => name !== '公営以外')
                .map(({ name }) => name)
                .join('/')}
            </td>
          </tr>
          <tr>
            <th>区画タイプ</th>
            <td>{section_types.map(({ name }) => name).join('/')}</td>
          </tr>
          <tr>
            <th>宗旨宗派</th>
            <td>{sects.map(({ name }) => name).join('/')}</td>
          </tr>
          {full && (
            <>
              {!!data.opening && (
                <tr>
                  <th>開園時間</th>
                  <td dangerouslySetInnerHTML={markup(data.opening)} />
                </tr>
              )}
              {!!data.section_count && (
                <tr>
                  <th>区画数</th>
                  <td dangerouslySetInnerHTML={markup(data.section_count)} />
                </tr>
              )}
              {!!data.total_area && (
                <tr>
                  <th>総面積</th>
                  <td dangerouslySetInnerHTML={markup(data.total_area)} />
                </tr>
              )}
              {!!data.began_date && (
                <tr>
                  <th>開園</th>
                  <td dangerouslySetInnerHTML={markup(data.began_date)} />
                </tr>
              )}
              {!!data.permission_number && (
                <tr>
                  <th>許可番号</th>
                  <td
                    dangerouslySetInnerHTML={markup(data.permission_number)}
                  />
                </tr>
              )}
              {!!data.phone_number && (
                <tr>
                  <th>電話番号</th>
                  <td dangerouslySetInnerHTML={markup(data.phone_number)} />
                </tr>
              )}
              {!!data.cultural_assets && (
                <tr>
                  <th>文化財</th>
                  <td dangerouslySetInnerHTML={markup(data.cultural_assets)} />
                </tr>
              )}
              {data.management ? (
                <tr>
                  <th>経営主体</th>
                  <td dangerouslySetInnerHTML={markup(data.management)} />
                </tr>
              ) : data.local_government &&
                cemetery_types.some(
                  ({ code }) => code === CemeteryTypeCode.KOEI
                ) ? (
                <tr>
                  <th>経営主体</th>
                  <td>
                    {data.local_government.name}
                    {/市|区$/.test(data.local_government.name)
                      ? '役所'
                      : '役場'}
                    <br />〒{data.local_government.postal_code}{' '}
                    {data.local_government.address}
                    <br />
                    TEL：{data.local_government.phone_number}
                  </td>
                </tr>
              ) : null}
              {!!data.events && (
                <tr>
                  <th>催事情報</th>
                  <td dangerouslySetInnerHTML={markup(data.events)} />
                </tr>
              )}
              {celebrities.length > 0 && (
                <tr>
                  <th>埋葬されている著名人</th>
                  <td>
                    <Celebrities celebrities={celebrities} />
                  </td>
                </tr>
              )}
              {!!data.seasonal_feature && (
                <tr>
                  <th>季節の見どころ</th>
                  <td dangerouslySetInnerHTML={markup(data.seasonal_feature)} />
                </tr>
              )}
              {!!data.remarks && (
                <tr>
                  <th>備考</th>
                  <td dangerouslySetInnerHTML={markup(data.remarks)} />
                </tr>
              )}
              {!!data.official_site && (
                <tr>
                  <th>公式サイト</th>
                  <td>
                    <a
                      href={data.official_site}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {name}
                    </a>
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
    </section>
  )
}

interface CelebritiesProps {
  celebrities: Details_details$data['celebrities']
}

const Celebrities: FC<CelebritiesProps> = ({ celebrities }) => {
  const celebs = useMemo(() => makeCelebrities(celebrities), [celebrities])
  return (
    <>
      {Object.entries(celebs).map(([genre, names]) => (
        <Fragment key={genre}>
          <div>【 {genre} 】</div>
          <span>{names.join('、')}</span>
        </Fragment>
      ))}
    </>
  )
}

const fragment = graphql`
  fragment Details_details on Cemetery {
    name
    kana
    address
    details {
      began_date
      cultural_assets
      events
      local_government {
        address
        kana
        name
        phone_number
        postal_code
      }
      management
      official_site
      opening
      permission_number
      phone_number
      postal_code
      remarks
      seasonal_feature
      section_count
      total_area
      local_government {
        address
        postal_code
        phone_number
        name
      }
    }
    celebrities {
      name
      genre
    }
    section_types {
      name
    }
    cemetery_types {
      name
      code
    }
    sects {
      name
    }
  }
`
