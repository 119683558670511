import { useEffect, useRef, FC } from 'react'
import { DetailTable, DetailTableProps } from './DetailTable'
import { Labels, LabelsProps } from './Labels'
import { PurchasePrice, PurchasePriceProps } from './PurchasePrice'
import Image from 'next/image'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import { mq } from '@lifedot/styles/mediaQuery'
import { AddMarginWrapper } from '@lifedot/components/AddMarginWrapper'
import { useRouterHash } from '@lifedot/hooks/use-router-hash'
import { Accordion } from '@lifedot/atoms/Accordion'
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined'
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined'

const styles = {
  spec: css({
    backgroundColor: palette.gray1,
    'details[open] .open': {
      display: 'none'
    },
    'details:not([open]) .close': {
      display: 'none'
    }
  }),
  specSummary: css({
    padding: '8px 12px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    svg: {
      display: 'block'
    }
  }),
  specDetail: css({
    padding: '0 12px 8px',
    whiteSpace: 'pre-wrap'
  }),
  detail: css({
    display: 'flex'
  }),
  img: css({
    flexShrink: 0,
    marginRight: 12,
    [mq('sp')]: {
      width: '35%',
      marginRight: 8
    }
  })
}

interface SectionTypePriceItemProps
  extends LabelsProps,
    DetailTableProps,
    PurchasePriceProps {
  name: string | null
  image: {
    url: string
    alt: string | null
  } | null
  spec: string | null
  hash: string
}

export const SectionTypePriceItem: FC<SectionTypePriceItemProps> = ({
  name,
  image,
  spec,
  ...props
}) => {
  const [hash] = useRouterHash(true)
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (hash === props.hash) ref.current?.scrollIntoView({ behavior: 'smooth' })
  }, [hash, props.hash])

  return (
    <section ref={ref} id={props.hash}>
      {name && <h4>{name}</h4>}
      <AddMarginWrapper spacing={2}>
        <PurchasePrice {...props} />
        <div css={styles.detail}>
          {image && (
            <figure css={styles.img}>
              <Image
                src={image.url}
                alt={image.alt ?? '区画写真'}
                width={215}
                height={156}
              />
            </figure>
          )}
          <DetailTable {...props} />
        </div>
        <Labels {...props} />
        {spec && (
          <div css={styles.spec}>
            <Accordion
              title={
                <p css={[typography.textS, styles.specSummary]}>
                  備考
                  <span className="open">
                    <AddBoxOutlinedIcon fontSize="small" />
                  </span>
                  <span className="close">
                    <IndeterminateCheckBoxOutlinedIcon fontSize="small" />
                  </span>
                </p>
              }
            >
              <p css={[typography.textS, styles.specDetail]}>{spec}</p>
            </Accordion>
          </div>
        )}
      </AddMarginWrapper>
    </section>
  )
}
