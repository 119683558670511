import { Spec_spec$data } from './__generated__/Spec_spec.graphql'

interface Contents<T extends string> {
  flagName: T
  label: string
}

const specContents: Contents<keyof Spec_spec$data['flags']>[] = [
  { flagName: 'danka_system_unnecessary', label: '檀家になる必要なし' },
  { flagName: 'lifetime_request', label: '生前申込可能' },
  { flagName: 'successor_unnecessary', label: '継承者がいらない' },
  { flagName: 'pet', label: 'ペット供養' },
  { flagName: 'universal_facility', label: 'バリアフリー' },
  { flagName: 'goushi', label: '合祀墓' },
  { flagName: 'hall', label: '法要できる' },
  { flagName: 'dining', label: '会食できる' },
  { flagName: 'parking', label: '駐車場' },
  { flagName: 'shuttle_bus', label: '送迎バス' },
  { flagName: 'shop', label: '売店' },
  { flagName: 'resident_staff', label: '管理者常駐' }
]

export const displayableSpecs = (
  optionalSpecs:
    | undefined
    | Array<keyof Spec_spec$data['flags'] | keyof Spec_spec$data['details']>
): Contents<keyof Spec_spec$data['flags']>[] => {
  return optionalSpecs
    ? optionalSpecs.reduce<Contents<keyof Spec_spec$data['flags']>[]>(
        (res, flagName) => {
          const item = specContents.find((item) => item.flagName === flagName)
          return item ? [...res, item] : res
        },
        []
      )
    : specContents
}

const detailsContents: Contents<keyof Spec_spec$data['details']>[] = [
  { flagName: 'universal_facility', label: 'バリアフリー設備詳細' },
  { flagName: 'facility', label: '設備詳細' }
]

export const displayableDetails = (
  optionalDetails:
    | undefined
    | Array<keyof Spec_spec$data['flags'] | keyof Spec_spec$data['details']>
): Contents<keyof Spec_spec$data['details']>[] => {
  return optionalDetails
    ? optionalDetails.reduce<Contents<keyof Spec_spec$data['details']>[]>(
        (res, flagName) => {
          const item = detailsContents.find(
            (item) => item.flagName === flagName
          )
          return item ? [...res, item] : res
        },
        []
      )
    : detailsContents
}
