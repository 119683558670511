/**
 * @generated SignedSource<<8f0b637dafcfce13bfc176dc4f5a0216>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime'
import { FragmentRefs } from 'relay-runtime'
export type Details_details$data = {
  readonly name: string
  readonly kana: string | null
  readonly address: string | null
  readonly details: {
    readonly began_date: string
    readonly cultural_assets: string
    readonly events: string
    readonly local_government: {
      readonly address: string
      readonly kana: string
      readonly name: string
      readonly phone_number: string
      readonly postal_code: string
    } | null
    readonly management: string
    readonly official_site: string
    readonly opening: string
    readonly permission_number: string
    readonly phone_number: string
    readonly postal_code: string
    readonly remarks: string
    readonly seasonal_feature: string
    readonly section_count: string
    readonly total_area: string
  }
  readonly celebrities: ReadonlyArray<{
    readonly name: string
    readonly genre: string
  }>
  readonly section_types: ReadonlyArray<{
    readonly name: string
  }>
  readonly cemetery_types: ReadonlyArray<{
    readonly name: string
    readonly code: string
  }>
  readonly sects: ReadonlyArray<{
    readonly name: string
  }>
  readonly ' $fragmentType': 'Details_details'
}
export type Details_details$key = {
  readonly ' $data'?: Details_details$data
  readonly ' $fragmentSpreads': FragmentRefs<'Details_details'>
}

const node: ReaderFragment = (function () {
  var v0 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null
    },
    v1 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'kana',
      storageKey: null
    },
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'address',
      storageKey: null
    },
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'phone_number',
      storageKey: null
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'postal_code',
      storageKey: null
    },
    v5 = [v0 /*: any*/]
  return {
    argumentDefinitions: [],
    kind: 'Fragment',
    metadata: null,
    name: 'Details_details',
    selections: [
      v0 /*: any*/,
      v1 /*: any*/,
      v2 /*: any*/,
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryDetails',
        kind: 'LinkedField',
        name: 'details',
        plural: false,
        selections: [
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'began_date',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'cultural_assets',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'events',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            concreteType: 'CemeteryLocalGoverment',
            kind: 'LinkedField',
            name: 'local_government',
            plural: false,
            selections: [
              v2 /*: any*/,
              v1 /*: any*/,
              v0 /*: any*/,
              v3 /*: any*/,
              v4 /*: any*/
            ],
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'management',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'official_site',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'opening',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'permission_number',
            storageKey: null
          },
          v3 /*: any*/,
          v4 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'remarks',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'seasonal_feature',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'section_count',
            storageKey: null
          },
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'total_area',
            storageKey: null
          }
        ],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryCelebrity',
        kind: 'LinkedField',
        name: 'celebrities',
        plural: true,
        selections: [
          v0 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'genre',
            storageKey: null
          }
        ],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryChoice',
        kind: 'LinkedField',
        name: 'section_types',
        plural: true,
        selections: v5 /*: any*/,
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryChoice',
        kind: 'LinkedField',
        name: 'cemetery_types',
        plural: true,
        selections: [
          v0 /*: any*/,
          {
            alias: null,
            args: null,
            kind: 'ScalarField',
            name: 'code',
            storageKey: null
          }
        ],
        storageKey: null
      },
      {
        alias: null,
        args: null,
        concreteType: 'CemeteryChoice',
        kind: 'LinkedField',
        name: 'sects',
        plural: true,
        selections: v5 /*: any*/,
        storageKey: null
      }
    ],
    type: 'Cemetery',
    abstractKey: null
  }
})()

;(node as any).hash = 'abcc076fe71869ea25bae6f213fa9bcc'

export default node
