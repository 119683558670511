import {
  deducePurchasePrice,
  PriceBase,
  priceFormatter,
  supplementString,
  unitString
} from '@lifedot/utils'

export const makeCemeteryPrice = (
  price: PriceBase | null
): {
  priceNumber: string
  priceSuffix: string
  supplement: string
} | null => {
  if (!price) return null

  const [priceNumber, priceSuffix] = priceFormatter(
    deducePurchasePrice(price),
    { split: true }
  )

  if (!priceNumber) return null

  const unit = unitString(price)
  const supplement = supplementString(price)

  return {
    priceNumber,
    priceSuffix: priceSuffix + unit,
    supplement: supplement.includes('墓石代')
      ? '(墓石代別)'
      : supplement.includes('その他')
      ? '(+その他費用)'
      : ''
  }
}
