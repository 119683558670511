import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import { FC } from 'react'
import { makeSupplement } from './dependencies'

const styles = {
  price: css({
    color: palette.alert,
    marginRight: 8
  })
}

export interface PurchasePriceProps {
  purchasePrice: {
    price: string
    unit: string
    supplement: string
    managementFee: string
  }
}

export const PurchasePrice: FC<PurchasePriceProps> = ({
  purchasePrice: { price, unit, supplement, managementFee }
}) => {
  const displaySupplement = makeSupplement({ supplement })
  return (
    <p css={typography.textS}>
      参考価格：
      <span css={styles.price}>
        <b css={typography.textL}>{price}</b>
        {unit}
        {displaySupplement}
      </span>
      / 年間管理費：{managementFee}
    </p>
  )
}
