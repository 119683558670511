import {
  CemeteryTypeCode,
  SectionTypeCode,
  sectionTypes
} from '@lifedot/constants/searchChoices'
import { Prices_prices$data } from './__generated__/Prices_prices.graphql'
import {
  deducePurchasePrice,
  displayablePurchasePrices,
  isReference,
  priceRound
} from '@lifedot/utils'
import { useRouterHash } from '@lifedot/hooks/use-router-hash'
import { useCallback, useMemo } from 'react'

export const isKoei = (choices: ReadonlyArray<{ code: string }>): boolean => {
  return choices.some(({ code }) => code === CemeteryTypeCode.KOEI)
}

export type ConvertedPriceData = {
  purchasePrice: ConvertedPurchasePriceObject
  details: ConvertedPriceDetails
  labels: ConvertedPriceLabels
  name: string | null
  image: {
    url: string
    alt: string | null
  } | null
  spec: string | null
  hash: string
}

export const makeTitle = (
  name: string,
  requestable: boolean,
  isKoei: boolean
): string => {
  return `${name}の${requestable && isKoei ? '公式' : ''}価格情報`
}

export const makeConvertedPricesGroupedByCode = (
  priceAndSpecs: Prices_prices$data['price_and_specs'],
  isKoei: boolean,
  lifetimeRequestabel: boolean,
  requestable: boolean
): Record<string, ConvertedPriceData[]> => {
  const sorted = [...priceAndSpecs].sort((a, b) =>
    (deducePurchasePrice(a) || Infinity) < (deducePurchasePrice(b) || Infinity)
      ? -1
      : 1
  )

  return sorted.reduce<Record<string, ConvertedPriceData[]>>(
    (res, priceAndSpec) => {
      return {
        ...res,
        [priceAndSpec.category_code]: [
          ...(res[priceAndSpec.category_code] ?? []),
          {
            ...convert(priceAndSpec, isKoei, lifetimeRequestabel, requestable),
            name: priceAndSpec.name,
            image: priceAndSpec.image,
            spec: priceAndSpec.spec,
            hash: `${priceAndSpec.category_code}-detail-${priceAndSpec.priceId}`
          }
        ]
      }
    },
    {}
  )
}

export type ConvertedPurchasePriceObject = {
  price: string
  unit: string
  supplement: string
  managementFee: string
}

export const purchasePrice = (
  priceAndSpec: Prices_prices$data['price_and_specs'][number]
): ConvertedPurchasePriceObject => {
  const _purchasePrice = displayablePurchasePrices(
    priceAndSpec,
    '未掲載',
    'パンフレットにてご確認ください'
  )
  return {
    price: _purchasePrice.priceNumber,
    unit: _purchasePrice.priceUnit,
    supplement: _purchasePrice.priceSupplement,
    managementFee: _purchasePrice.managementFee
  }
}

export type ConvertedPriceDetails = { label: string; content: string }[]

const details = (
  priceAndSpec: Prices_prices$data['price_and_specs'][number],
  isKoei: boolean,
  isNormal: boolean,
  requestable: boolean
): ConvertedPriceDetails => {
  const {
    price1,
    price1_unit,
    price3,
    price3_unit,
    category_code,
    purchase_price,
    lot_area,
    simulated_price3,
    price4_name,
    price4,
    purchase_price_option,
    price4_unit,
    lot_area_spec
  } = priceAndSpec

  const details = []

  if (price1 && Number.isFinite(price1))
    details.push({
      label: '利用料\n(永代使用料)',
      content: `${price1.toLocaleString()}${price1_unit}`
    })
  if (isNormal && price3 && Number.isFinite(price3)) {
    details.push({
      label: '墓石制作・工事費',
      content: `${price3.toLocaleString()}${price3_unit || '円'}`
    })
  }
  if (
    simulated_price3 &&
    isReference({
      category_code,
      lot_area,
      price1,
      price3,
      purchase_price,
      simulated_price3
    })
  ) {
    // 石材店へのペナルティ防止の為、表示金額が霊園の最低価格を下回らないように参考墓石工事代に10%上乗せした価格を足したものを元にする
    details.push({
      label: '墓石制作・工事費',
      content: `${priceRound(
        Math.round(simulated_price3 * 1.1)
      ).toLocaleString()}${price3_unit || '円〜'}`
    })
  }

  if (
    price4 &&
    price4_name &&
    (Number.isFinite(price4) || purchase_price_option === 'required_price4')
  )
    details.push({
      label: price4_name,
      content: Number.isFinite(price4)
        ? `${price4.toLocaleString()}${price4_unit ?? ''}`
        : ''
    })
  if (lot_area || lot_area_spec)
    details.push({
      label: '面積/特徴',
      content: `${lot_area ? `${lot_area}㎡ ` : ''}${lot_area_spec ?? ''}`
    })
  details.push({
    label: '空き状況',
    content: !isKoei && requestable ? '空きあり' : '要確認'
  })

  return details
}

type ConvertedPriceLabels = string[]

const labels = (
  priceAndSpec: Prices_prices$data['price_and_specs'][number],
  isKoei: boolean,
  isNormal: boolean,
  lifetimeRequestabel: boolean
): ConvertedPriceLabels => {
  const capacity = labelByCemeteryPriceCapacity(isNormal, priceAndSpec.capacity)
  const labels = []
  if (capacity) labels.push(capacity)
  if (priceAndSpec.price2 === 0) labels.push('維持費不要')
  if (!isKoei && lifetimeRequestabel) labels.push('生前申込可能')
  if (
    priceAndSpec.name?.includes('ペット') ||
    priceAndSpec.spec?.includes('ペット')
  )
    labels.push('ペット埋葬可能')

  return labels
}

const labelByCemeteryPriceCapacity = (
  isNormal: boolean,
  capacity: number | null
): string | null => {
  if (isNormal) return 'ご家族様向け'
  if (capacity === null) return null

  switch (capacity) {
    case 1:
      return 'お1人様向け'
    case 2:
      return 'お2人様向け'
    default:
      return 'ご家族様向け'
  }
}

export const convert = (
  priceAndSpec: Prices_prices$data['price_and_specs'][number],
  isKoei: boolean,
  lifetimeRequestabel: boolean,
  requestable: boolean
): {
  purchasePrice: ConvertedPurchasePriceObject
  details: ConvertedPriceDetails
  labels: ConvertedPriceLabels
} => {
  return {
    purchasePrice: purchasePrice(priceAndSpec),
    details: details(
      priceAndSpec,
      isKoei,
      priceAndSpec.category_code === SectionTypeCode.NORMAL,
      requestable
    ),
    labels: labels(
      priceAndSpec,
      isKoei,
      priceAndSpec.category_code === SectionTypeCode.NORMAL,
      lifetimeRequestabel
    )
  }
}

export const usePricesTabAndMode = (
  items: Record<string, unknown>
): [
  {
    modes: typeof _modes
    tabs: { code: string; name: string }[]
    currentTab: number
    currentMode: (typeof _modes)[number]['code']
  },
  {
    handleMode: (value: (typeof _modes)[number]['code']) => void
    handleTab: (value: number) => void
  }
] => {
  const [hash, setHash] = useRouterHash(true)
  const tabs = useMemo(() => makeTabList(items), [items])

  const currentTab = useMemo(
    () =>
      Math.max(
        tabs.findIndex(({ code }) => hash.includes(code)),
        0
      ),
    [hash, tabs]
  )
  const currentMode = useMemo(
    () => _modes.find(({ code }) => hash.includes(code))?.code ?? _detail.code,
    [hash]
  )
  const handleTab = useCallback(
    (value: number) => {
      setHash(`${tabs[value]?.code}-${currentMode}`)
    },
    [currentMode, setHash, tabs]
  )
  const handleMode = useCallback(
    (value: (typeof _modes)[number]['code']) => {
      setHash(`${tabs[currentTab]?.code}-${value}`)
    },
    [currentTab, setHash, tabs]
  )

  return [
    { currentTab, currentMode, modes: _modes, tabs },
    { handleTab, handleMode }
  ]
}

const makeTabList = (
  priceItems: Record<string, unknown>
): { code: string; name: string }[] => {
  const keys = Object.keys(priceItems)
  return _tabs.filter((tab) => keys.includes(tab.code))
}

const _tabs = [...sectionTypes, { code: 'other', name: 'その他' }]
const _detail = { code: 'detail' as const, name: '詳細表示' }
const _modes: { code: 'detail' | 'chart'; name: string }[] = [
  _detail,
  { code: 'chart', name: '一覧表示' }
]
